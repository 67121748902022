<template>
  <div>
    <vs-button
      @click="openPopUpFilters()"
      class="bt2"
      style="margin-bottom: 1em"
      >Mostrar filtros</vs-button
    >
    <vs-button @click="resetFilters()" class="bt2" style="margin-bottom: 1em"
      >Limpar filtros</vs-button
    >
    <kanban-board :stages="stages" :blocks="blocks" @update-block="updateBlock">
      <div v-for="stage in stages" :slot="stage" :key="stage">
        <h2>{{ stage }}</h2>
        <p>
          <strong>{{ count(stage) }} </strong>ticket(s)
        </p>
      </div>
      <div
        v-for="block in blocks"
        :slot="block.id"
        :key="block.id"
        @click="openPopUp(block)"
      >
        <div>
          <strong> {{ block.code }}</strong>
        </div>
        <div>
          <p>{{ block.description }}</p>
        </div>
        <div class="classification">
          <p v-bind:style="{ color: block.color }">
            {{ block.classification.name }}
          </p>
        </div>
      </div>
    </kanban-board>

    <vs-popup
      :active.sync="showOrderDetails"
      title="Detalhes da requisição"
      v-if="showOrderDetails"
      fullscreen
    >
      <vs-card>
        <div slot="header">
          <h2>Ticket Nro. {{ orderSelected.code }}</h2>
        </div>
        <div class="card">
          <vs-row vs-w="12">
            <vs-col vs-w="6">
              <p>
                Requisição criada por {{ orderSelected.createdUser.name }} em
                {{ orderSelected.created }} - última atualização em
                {{ orderSelected.updated }}
              </p>
              <p>
                {{ orderSelected.status }} -
                {{ orderSelected.classification.name }} -
                {{
                  orderSelected.makeUnit
                    ? orderSelected.makeUnit.name
                    : "Sem unidade atribuída"
                }}
              </p>
              <p>
                Código SAP:
                {{
                  orderSelected.items[0].code
                    ? orderSelected.items[0].code
                    : "Não possui"
                }}
              </p>
              <p v-if="orderSelected.items[0].code">
                Valor SAP: R$
                {{ orderSelected.items[0].project.sapValue }}
              </p>
              <p>
                Família:
                {{
                  orderSelected.items[0].project.family
                    ? orderSelected.items[0].project.family.name
                    : "-"
                }}
              </p>
              <p>Descrição: {{ orderSelected.items[0].project.description }}</p>
              <p>Quantidade: {{ orderSelected.items[0].qtd }}</p>
              <p>Impacto: {{ orderSelected.impact }}</p>
              <p>
                Projetista:
                {{
                  orderSelected.items[0].project.designerUser
                    ? orderSelected.items[0].project.designerUser.name
                    : "Sem projetista"
                }}
              </p>
              <fieldset v-if="hasPermission != -1">
                <legend>
                  Projeto {{ orderSelected.items[0].project.code }}
                </legend>
                <h3>{{ orderSelected.items[0].project.name }}</h3>
                <vs-row vs-w="12">
                  <vs-col vs-w="6" class="spacing">
                    <p>
                      Manufatura:
                      <select
                        v-validate="'required'"
                        name="manufacture"
                        v-model="orderSelected.items[0].project.manufacture"
                        label="Manufatura"
                        class="w-full mb-6 select1"
                        data-vv-as="Manufatura"
                      >
                        <option
                          :key="index"
                          :value="item.id"
                          v-for="(item, index) in manufactureOptions"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </p>
                    <p>
                      Complexidade:
                      <select
                        v-validate="'required'"
                        name="complex"
                        v-model="orderSelected.items[0].project.complex"
                        label="Complexidade"
                        class="w-full mb-6 select1"
                        data-vv-as="Complexidade"
                      >
                        <option
                          :key="index"
                          :value="item.id"
                          v-for="(item, index) in complexOptions"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </p>

                    <p>
                      Proporção:
                      <br />
                      <vs-input
                        class="w-full mb-6"
                        v-model="orderSelected.items[0].project.proportion"
                        :text="orderSelected.items[0].project.proportion"
                      ></vs-input>
                    </p>

                    <p>
                      Material
                      <select
                        v-validate="'required'"
                        name="material"
                        v-model="orderSelected.items[0].project.material"
                        label="Material"
                        class="w-full mb-6 select1"
                        data-vv-as="Material"
                      >
                        <option
                          :key="index"
                          :value="item"
                          v-for="(item, index) in materialOptions"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </p>

                    <p>
                      Massa:
                      <br />
                      <vs-input
                        class="w-full mb-6"
                        v-model="orderSelected.items[0].project.mass"
                        :text="orderSelected.items[0].project.mass"
                      ></vs-input>
                    </p>
                  </vs-col>
                  <vs-col vs-w="6">
                    <p>
                      Tempo:
                      <br />
                      <vs-input
                        v-mask="'##:##'"
                        class="w-full mb-6"
                        v-model="orderSelected.items[0].project.time"
                        :text="orderSelected.items[0].project.time"
                      ></vs-input>
                    </p>
                    <p>
                      Quantidade impressa:
                      <br />
                      <vs-input
                        class="w-full mb-6"
                        v-model="orderSelected.items[0].qtdProduced"
                        :text="orderSelected.items[0].qtdProduced"
                      ></vs-input>
                    </p>
                    <p v-if="orderSelected.productionValue">
                      Valor de produção:
                      <br />
                      <vs-input
                        class="w-full mb-6"
                        v-model="orderSelected.productionValue"
                        :text="orderSelected.productionValue"
                      ></vs-input>
                    </p>
                    <p>
                      <vs-button class="bt2" @click="updateProject()"
                        >Atualizar</vs-button
                      >
                    </p>
                  </vs-col>
                </vs-row>
              </fieldset>
            </vs-col>
            <vs-col vs-w="6" class="line">
              <img
                :src="orderSelected.imageUrl"
                title="Imagem do Projeto"
                width="90%"
                v-if="orderSelected.items[0].project.image"
              />
              <p v-else>Sem imagem</p>
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </vs-popup>

    <vs-popup title="Filtros" :active.sync="showFilters" fullscreen>
      <vs-card>
        <div slot="header">
          <h3>Filtros</h3>
        </div>
        <div>
          <vs-row vs-w="12">
            <vs-col vs-w="2" class="margem">
              Unidade Solicitante
              <select
                name="classification"
                v-model="filters.requestUnitId"
                label="Unidade Solicitante"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in unitOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Unidade de Criação
              <select
                name="classification"
                v-model="filters.makeUnitId"
                label="Unidade de Criação"
                class="w-full mb-6 select1"
                v-on:change="loadUsers()"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in unitOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Projetista
              <select
                name="designerId"
                v-model="filters.designerId"
                label="Projetista"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in userOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Classificação
              <select
                name="classification"
                v-model="filters.classificationId"
                label="Classificação"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in classificationOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Região
              <select
                name="Região"
                v-model="filters.region"
                label="Região"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item"
                  v-for="(item, index) in regionOptions"
                >
                  {{ item }}
                </option>
              </select>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="2" class="margem">
              Criado de
              <vs-input
                type="date"
                name="createdAtFrom"
                v-model="filters.createdAtFrom"
              ></vs-input>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Criado até
              <vs-input
                type="date"
                name="createdAtTo"
                v-model="filters.createdAtTo"
              ></vs-input>
            </vs-col>
            <vs-col vs-w="3" class="margem">
              <vs-button @click="loadKanBan()" class="bt2">Aplicar</vs-button>
              <vs-button @click="resetFilters()" class="bt2"
                >Limpar filtros</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </vs-popup>
  </div>
</template>
<script>
import siriusAPI from "../services";
import {
  notifyError,
  notifySuccess,
  formatDateToLocale,
} from "@/utils/helpers";

export default {
  data() {
    return {
      showFilters: false,
      stages: [],
      blocks: [],
      phasesOptions: [],
      hasPermission: -1,
      showOrderDetails: false,
      orderSelected: null,
      isAdmin: false,
      materialOptions: [],
      manufactureOptions: [
        { id: 1, name: "Aditiva" },
        { id: 2, name: "Extrativa " },
        { id: 3, name: "Injeção" },
        { id: 4, name: "Injeção/Usinagem" },
        { id: 5, name: "Impressão 3D" },
        { id: 6, name: "IMPRESSÃO 3D - FDM" },
        { id: 7, name: "TORNO CNC" },
        { id: 8, name: "IMPRESSÃO 3D - HP" },
        { id: 9, name: "TORNO CNC E FRESA CNC" },
      ],
      classificationOptions: [],
      unitOptions: [],
      regionOptions: [],
      userOptions: null,
      loggedUser: null,
      filters: {
        requestUnitId: "",
        makeUnitId: "",
        region: "",
        classificationId: "",
        designerId: "",
        createdAtFrom: null,
        createdAtTo: null,
      },
      complexOptions: [
        { id: "1", name: "Fácil" },
        { id: "2", name: "Médio" },
        { id: "3", name: "Difícil" },
      ],
    };
  },
  mounted() {
    this.loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = this.loggedUser.profile.name;
    const profiles = ["Administrador", "Projetista"];
    this.isAdmin = userProfile == profiles[0];

    this.loadKanBan();
    this.loadMaterials();
    this.loadClassifications();
    this.loadUnits();
    this.loadUsers();
    this.loadRegions();

    new siriusAPI()
      .getPhases()
      .then((res) => {
        this.phasesOptions = res.data;
      })
      .catch((error) => {
        notifyError(this, error);
      });
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    openPopUpFilters() {
      this.showFilters = true;
    },
    loadClassifications() {
      new siriusAPI()
        .getClassifications()
        .then((res) => {
          this.classificationOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de classificação: " + error
          );
        });
    },

    loadRegions() {
      new siriusAPI()
        .getRegions()
        .then((res) => {
          this.regionOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de região: " + error
          );
        });
    },
    loadUsers() {
      this.$vs.loading();
      new siriusAPI()
        .getUsersWithParams(null, this.filters.makeUnitId, 3)
        .then((res) => {
          this.userOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de usuários " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    loadUnits() {
      new siriusAPI()
        .getUnits()
        .then((res) => {
          this.unitOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de unidades " + error
          );
        });
    },
    resetFilters() {
      this.filters = {
        requestUnitId: "",
        makeUnitId: "",
        region: "",
        classificationId: "",
        designerId: "",
        createdAtFrom: null,
        createdAtTo: null,
      };
      this.loadKanBan();
    },
    openPopUp(order) {
      this.orderSelected = order;
      this.getOrder(order.id);
      this.showOrderDetails = true;
    },
    count(stage) {
      let count = 0;
      this.blocks.forEach((element) => {
        if (element.status == stage) {
          count++;
        }
      });
      return count;
    },
    async updateBlock(id, status) {
      if (this.hasPermission != -1) {
        const phase = this.phasesOptions.find((fase) => fase.name == status);
        const block = this.blocks.find((block) => block.id == id);

        if (phase.index < block.phase.index) {
          notifyError(this, "Não é permitido retornar a status anteriores!");
          this.loadKanBan();
          return;
        }

        const payload = {
          phaseId: phase.id,
        };
        this.$vs.loading();
        await new siriusAPI()
          .updatePhase(block.uuid, payload)
          .then(() => {
            notifySuccess(this, "Status atualizado com sucesso");
            this.loadKanBan();
          })
          .catch((error) => {
            notifyError(this, "Erro ao tentar atualizado o status: " + error);
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      } else {
        notifyError(this, "Você não tem permissão para esta operação.");
        this.loadKanBan();
      }
    },

    getOrder(orderId) {
      this.$vs.loading();
      new siriusAPI()
        .getOrder(orderId)
        .then((res) => {
          Object.assign(this.orderSelected, res.data);
          if (this.orderSelected.items[0].project.image) {
            this.orderSelected.imageUrl =
              process.env.VUE_APP_URL_IMAGES +
              this.orderSelected.items[0].project.image;
          }
          if (this.orderSelected.items[0].project.designerUser) {
            if (
              this.orderSelected.items[0].project.designerUser.id ==
              this.loggedUser.id
            ) {
              this.hasPermission = true;
            }
          }
        })
        .catch(() => {
          notifyError(
            this,
            "Não foi possível recuperar informações do pedido!"
          );
          this.showOrderDetails = false;
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async loadMaterials() {
      this.$vs.loading();
      new siriusAPI()
        .getMaterials()
        .then((res) => {
          this.materialOptions = res.data;
          if (this.materialOptions[0] == undefined)
            notifyError(this, "Não há materiais cadastrados!");
          this.$vs.loading.close();
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de materiais: " + error
          );
          this.$vs.loading.close();
        });
    },

    updateProject() {
      const projectUuid = this.orderSelected.items[0].project.uuid;
      const orderUuid = this.orderSelected.uuid;
      const payload = {
        qtd: this.orderSelected.items[0].qtd,
        qtdProduced: this.orderSelected.items[0].qtdProduced, //conferir
        project: {
          name: this.orderSelected.items[0].project.name,
          manufacture: this.orderSelected.items[0].project.manufacture,
          kpi: this.orderSelected.items[0].project.kpi,
          sapValue: this.orderSelected.items[0].project.sapValue,
          complex: this.orderSelected.items[0].project.complex,
          proportion: this.orderSelected.items[0].project.proportion,
          description: this.orderSelected.items[0].project.description,
          materialId: this.orderSelected.items[0].project.material.id,
          familyId: this.orderSelected.items[0].project.family.id,
          equipmentId: this.orderSelected.items[0].project.equipment.id,
          mass: this.orderSelected.items[0].project.mass,
          time: this.orderSelected.items[0].project.time,
        },
      };
      this.$vs.loading();
      new siriusAPI()
        .updateProject(payload, orderUuid, projectUuid)
        .then(() => {
          notifySuccess(this, "Projeto atualizado com sucesso!");
          this.showOrderDetails = false;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(
            this,
            "Erro ao tentar carregar atualizar o projeto: " + error.message
          );
        });
    },

    async loadKanBan() {
      this.$vs.loading();
      this.blocks = [];
      new siriusAPI()
        .getKanBan(this.filters)
        .then((res) => {
          let result = res.data;
          this.stages = Object.keys(result);
          let orders = Object.values(result);
          orders.forEach((element) => {
            element.forEach((order) => {
              this.blocks.push({
                id: order.id,
                status: order.phase.name,
                phase: order.phase,
                description: order.description,
                code: order.code,
                unit: order.makeUnit,
                classification: order.classification,
                color: order.classification.color,
                uuid: order.uuid,
                items: order.items,
                created: formatDateToLocale(order.createdAt),
                updated: formatDateToLocale(order.updatedAt),
              });
            });
          });
        })
        .catch((error) => {
          notifyError(this, "Erro ao carregar o kanban: " + error);
        })
        .finally(() => {
          this.$vs.loading.close();
          this.showFilters = false;
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.spacing {
  padding-right: 3%;
}
.margem {
  margin-right: 3%;
}

fieldset {
  border: 0.8px solid #b6b2b2;
  padding: 0.5em;
}
fieldset legend {
  font-weight: bold;
}
.bt {
  margin-right: 1em;
}
.card {
  margin-bottom: 3em;
}
.line {
  text-align: center;
}
.bt2 {
  margin-top: 0.5em;
  margin-right: 0.5em;
}
</style>

<style lang="scss">
@import "@/assets/kanban.scss";
</style>


